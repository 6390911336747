.heading{
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}


@media (max-width : 768px) {
    .frame-container {
        display: none;
    }
}

@media (max-width : 640px) {
    .frame-container {
        display: block;
    }
}



.frame-container {
    width: 300px;
    height: 576px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
}

.frame {
    width: 100%;
    height: 100%;
}