.register-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.register-form-bg {
  background-color: #e4e4e4d8;
}

.register-form-bg:focus,
.register-form-bg:focus-visible {
  outline: none;
  box-shadow: none;
}

.stripe-button {
  text-decoration: none;
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  background-color: white;
  padding: 10px 90px;
}

.qr {
  width: 280px;
  border-radius: 10px;
}
