.card {
    border: 4px solid rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.7);
}

.img-container {
    border: 5px solid white;
    align-items: center;
    justify-content: center;
}

.img-container img {
    min-height: 100%;
    object-fit: cover;
    min-width: 100%;
}