.team-item {
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.name, .position {
    font-size: 1.2rem;
    font-weight: normal;
    margin: 0;
    color: white;
    padding: 5px 0;
    text-align: center;
}

.position {
    color: #a3a3a3;
    text-align: center;
}

.img-container {
    width: 200px;
    height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 20px;
    border: 2px solid white;
}

.img-container.president {
    border: 4px solid #6e85d9;
    width: 210px;
    height: 210px;
}

@media screen and (max-width: 768px) {
    .img-container {
        width: 150px;
        height: 150px;
    }
    .img-container.president {
        width: 160px;
        height: 160px;
    }
}

.img-container img {
    width: 100%;
}