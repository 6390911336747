.title {
    color: #99a7d9;
    font-size: 2.5rem;
    display: inline-block;
    border-bottom: 1px solid #99a7d9;
    padding: 5px 60px!important;
    margin-bottom: 50px;
}

.projects {
    margin-bottom: 60px;
}