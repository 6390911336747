.background-img-container {
    z-index: -1;
}

.background-img-container::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    content: '';
}

.background-img {
    object-fit: cover;
    object-position: center;
    min-height: 100%;
}