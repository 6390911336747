.banner-container {
    width: 100vw;
    height: auto;
}

.banner-container .banner {
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}